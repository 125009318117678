<template>
  <div class="d-flex justify-content-between align-items-center mb-4">
    <h1 class="h3 mb-0">
      Users <small v-if="usersCount !== null" class="text-muted">({{ usersCount }})</small>
    </h1>
    <UserInvite
      v-if="$store.getters['publication/isAdmin']"
      :visible="visible"
      @cancel="visible = false"
      @success="visible = false"
    >
      <a-button type="primary" shape="circle" size="large" :disabled="loading" @click="visible = true">
        <PlusIcon class="icon" />
      </a-button>
    </UserInvite>
  </div>
  <BaseSpinner :loading="loading">
    <div class="row">
      <div v-for="user in users" class="col-12 col-lg-6 col-xl-4 mb-4">
        <UserItem :user="user" @success="listUsers" />
      </div>
    </div>
  </BaseSpinner>
  <BasePagination
    class="mt-4"
    v-model="filters.page"
    :hasMore="hasMore"
    :pageCount="Math.ceil(usersCount / filters.limit)"
  />
</template>

<script>
import { PlusIcon } from '@heroicons/vue/24/outline'
import BasePagination from '@/components/Base/BasePagination'
import BaseSpinner from '@/components/Base/BaseSpinner'
import UserInvite from '@/components/User/UserInvite'
import UserItem from '@/components/User/UserItem'

export default {
  components: {
    BasePagination,
    BaseSpinner,
    PlusIcon,
    UserInvite,
    UserItem,
  },

  data() {
    return {
      visible: false,
      loading: false,
      hasMore: false,
      users: [],
      usersCount: null,
      filters: {
        limit: 20,
        page: Number(this.$route.query.page) || 1,
      },
    }
  },

  async mounted() {
    await this.listUsers()
  },

  methods: {
    async listUsers() {
      try {
        this.loading = true

        var { data } = await this.$http.get(`/publications/${ this.$store.state.publication.data.id }/users`)

        this.users = data.users
        this.usersCount = data.usersCount
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          this.$message.error(err.message)
        }
      } finally {
        this.loading = false
      }
    },
  },

  watch: {
    'filters.page'(value) {
      this.$router.push({ name: 'publications-id-users', query: { ...this.$route.query, page: value }})
    },
  },
}
</script>
