<template>
  <slot />
</template>

<script>
export default {
  props: {
    visible: Boolean,
    user: Object,
  },

  data() {
    return {
      loading: false,
    }
  },

  methods: {
    async onConfirm() {
      try {
        this.loading = true

        await this.$http.delete(`/publications/${ this.$store.state.publication.data.id }/users/${ this.user.id }`)

        this.$message.success('User has been removed from the publication.')
        this.$emit('success')
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          this.$message.error(err.message)
        }
      } finally {
        this.$emit('cancel')
        this.loading = false
      }
    },
  },

  watch: {
    visible(value) {
      if (value) {
        if (this.user.role && this.user.role.admin) {
          this.$error({
            title: 'Cannot Remove Administrators',
            content: 'Administrators cannot be removed from a publication. Please downgrade the role before removing a user.',
            onOk: () => {
              this.$emit('cancel')
            },
          })
        } else {
          this.$confirm({
            title: 'Remove User',
            content: 'This user will be removed from your publication, but all content generated by the user will remain intact. The action cannot be undone.',
            okText: 'Remove',
            okType: 'danger',
            confirmLoading: this.loading,
            onOk: async () => {
              await this.onConfirm()
            },
            onCancel: () => {
              this.$emit('cancel')
            },
          })
        }
      } else {
        this.$emit('cancel')
      }
    },
  },
}
</script>
