<template>
  <slot />
  <a-drawer
    title="Edit Profile"
    :width="560"
    @close="$emit('cancel')"
    :visible="visible"
  >
    <p class="mb-4">API responses include details about {{ user.user.name }} when querying a article. You can customize them for this publication.</p>
    <a-form
      :colon="false"
      layout="vertical"
      :model="values"
      ref="form"
      @finish="onFinish"
    >
      <a-form-item
        label="Title"
        name="title"
      >
        <a-input
          v-model:value="values.title"
          :maxlength="50"
          name="title"
          placeholder="Position or title"
        />
      </a-form-item>
      <a-form-item
        label="Email Address"
        name="email"
        :rules="[
          { type: 'email', message: 'Email address is invalid.' },
        ]"
      >
        <a-input
          v-model:value="values.email"
          name="email"
          placeholder="name@company.com"
          type="email"
        />
      </a-form-item>
      <a-form-item
        label="Phone Number"
        name="phone"
      >
        <a-input
          v-model:value="values.phone"
          :maxlength="16"
          name="phone"
        />
      </a-form-item>
      <a-form-item
        label="Bio"
        name="bio"
      >
        <a-textarea
          v-model:value="values.bio"
          :maxlength="1000"
          name="bio"
          :rows="8"
          showCount
        />
      </a-form-item>
    </a-form>
    <template #extra>
      <a-button type="primary" html-type="submit" :loading="loading" @click="onFinish">
        Save
      </a-button>
    </template>
  </a-drawer>
</template>

<script>
export default {
  props: {
    visible: Boolean,
    user: Object,
  },

  data() {
    return {
      loading: false,
      values: {
        title: this.user.title,
        email: this.user.email,
        phone: this.user.phone,
        bio: this.user.bio,
      },
    }
  },

  methods: {
    async onFinish() {
      try {
        var values = await this.$refs.form.validateFields()

        this.loading = true

        var { data } = await this.$http.put(`/publications/${ this.$store.state.publication.data.id }/users/${ this.user.id }`, values)

        this.$message.success('User has been saved.')
        this.$emit('success', data)
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          this.$message.error(err.message)
        }
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
