<template>
  <slot />
  <a-modal
    title="Change Role"
    :closable="false"
    :confirmLoading="loading"
    :maskClosable="false"
    :okButtonProps="{
      disabled: values.role === user.role.id,
      htmlType: 'submit',
    }"
    okText="Confirm"
    :visible="visible"
    @cancel="$emit('cancel')"
    @ok="onFinish"
  >
    <p>You’re changing the role for {{ user.user.name }}. At least one user with administrator role must remain on a publication.</p>
    <a-form
      :colon="false"
      layout="vertical"
      :model="values"
      ref="form"
      @finish="onFinish"
    >
      <a-form-item
        label="Role"
        name="role"
      >
        <a-select
          v-model:value="values.role"
          name="role"
          :disabled="!roles.length"
          :loading="loadingRoles"
          allowClear
          placeholder="Select a role"
        >
          <a-select-option v-for="role in roles" :value="role.id">
            {{ role.title }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
export default {
  props: {
    visible: Boolean,
    user: Object,
  },

  data() {
    return {
      loading: false,
      loadingRoles: false,
      roles: [],
      values: {
        role: this.user.role.id || undefined,
      },
    }
  },

  async mounted() {
    await this.listRoles()
  },

  methods: {
    async listRoles() {
      try {
        this.loadingRoles = true

        var { data } = await this.$http.get(`/publications/${ this.$store.state.publication.data.id }/roles`)

        this.roles = data.roles.filter(item => !item.everyone)
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          this.$message.error(err.message)
        }
      } finally {
        this.loadingRoles = false
      }
    },
    async onFinish() {
      try {
        this.loading = true

        if (!values.role) { // Because value is undefined.
          values.role = null
        }

        var { data } = await this.$http.put(`/publications/${ this.$store.state.publication.data.id }/users/${ this.user.id }`, values)

        this.$message.success('User role has been saved.')
        this.$emit('success', data)
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          console.error(err)
        }
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
