<template>
  <slot />
</template>

<script>
export default {
  props: {
    visible: Boolean,
    email: String,
  },

  data() {
    return {
      loading: false,
    }
  },

  methods: {
    async onConfirm() {
      try {
        this.loading = true

        await this.$http.post(`/publications/${ this.$store.state.publication.data.id }/users`, {
          email: this.email,
        })

        this.$message.success(`${ this.email.toLowerCase() } has been invited to the site.`)
        this.$emit('success')
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          this.$message.error(err.message)
        }
      } finally {
        this.$emit('cancel')
        this.loading = false
      }
    },
  },

  watch: {
    visible(value) {
      if (value === true) {
        this.$confirm({
          title: 'Resend Invite',
          content: `A new invite email will be sent to ${ this.email } containing a unique invite link to join your site’s team.`,
          okText: 'Resend',
          confirmLoading: this.loading,
          onOk: async () => {
            await this.onConfirm()
          },
          onCancel: () => {
            this.$emit('cancel')
          },
        })
      } else {
        this.$emit('cancel')
      }
    },
  },
}
</script>
