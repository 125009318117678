<template>
  <slot />
  <a-modal
    title="Invite User"
    :closable="false"
    :confirmLoading="loading"
    :maskClosable="false"
    :okButtonProps="{ htmlType: 'submit' }"
    okText="Confirm"
    :visible="visible"
    @cancel="$emit('cancel')"
    @ok="onFinish"
  >
    <a-form
      :colon="false"
      layout="vertical"
      :model="values"
      ref="form"
      @finish="onFinish"
    >
      <a-form-item
        name="email"
        :rules="[
          { required: true, message: 'Email address is required.' },
          { type: 'email', message: 'Email address is invalid.' },
        ]"
      >
        <a-input
          v-model:value="values.email"
          name="email"
          placeholder="name@company.com"
        />
      </a-form-item>
    </a-form>
    <p class="small">We will send an email with an invite link to join your publication. The new user is initially added as <strong>contributor</strong>. You can change its role later.</p>
  </a-modal>
</template>

<script>
export default {
  props: {
    visible: Boolean,
  },

  data() {
    return {
      loading: false,
      values: {
        email: null,
      },
    }
  },

  methods: {
    async onFinish() {
      try {
        var values = await this.$refs.form.validateFields()

        this.loading = true

        var { data } = await this.$http.post(`/publications/${ this.$store.state.publication.data.id }/invites`, values)

        this.$message.success(`Invite sent to ${ values.email.toLowerCase() }.`)
        this.$emit('success', data)
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          console.error(err)
        }
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
