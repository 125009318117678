<template>
  <div class="card h-100">
    <div class="card-body text-center">
      <div v-if="user.user">
        <a-avatar
          :size="80"
          icon="user"
          :src="user.user.image.resolutions['160']"
          :alt="user.user.name"
        />
        <h2 class="h6 mt-3 mb-0">{{ user.user.name }}</h2>
        <p>{{ user.title }}</p>
        <div class="text-muted">
          <small>
            {{ user.role.title }}
          </small>
        </div>
      </div>
      <div v-else>
        <a-avatar
          :size="80"
          icon="user"
          :alt="user.invite.email"
        />
        <h2 class="h5 mt-3 mb-0">{{ user.invite.email }}</h2>
        <div>
          <a-tag color="orange">Pending Confirmation</a-tag>
        </div>
      </div>
    </div>
    <div v-if="$store.getters['publication/isAdmin']" class="card-footer text-end">
      <a-dropdown-button size="small" :trigger="['click']" @click="onClick">
        <UserDrawer
          v-if="user.verified"
          :visible="visible.update"
          :user="user"
          @cancel="visible.update = false"
          @success="onSuccess('update')"
        >
          Edit
        </UserDrawer>
        <UserResend
          v-else
          :visible="visible.resend"
          :email="user.invite.email"
          @cancel="visible.resend = false"
          @success="onSuccess('resend')"
        >
          <ArrowUturnDownIcon class="icon me-2" />Resend
        </UserResend>
        <template #overlay>
          <a-menu @click="onMenuClick">
            <a-menu-item v-if="user.verified" key="role">
              <user-role
                :visible="visible.role"
                :user="user"
                @cancel="visible.role = false"
                @success="onSuccess('role')"
              >
                <ArrowUturnDownIcon class="icon me-2" />Change Role
              </user-role>
            </a-menu-item>
            <a-menu-divider v-if="user.verified" />
            <a-menu-item class="text-danger" key="delete">
              <UserDelete
                :visible="visible.delete"
                :user="user"
                @cancel="visible.delete = false"
                @success="onSuccess('delete')"
              >
                <TrashIcon class="icon me-2" />Remove
              </UserDelete>
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown-button>
    </div>
  </div>
</template>

<script>
import { ArrowUturnDownIcon, TrashIcon } from '@heroicons/vue/24/outline'
import UserDelete from '@/components/User/UserDelete'
import UserDrawer from '@/components/User/UserDrawer'
import UserResend from '@/components/User/UserResend'
import UserRole from '@/components/User/UserRole'

export default {
  components: {
    ArrowUturnDownIcon,
    TrashIcon,
    UserDelete,
    UserDrawer,
    UserResend,
    UserRole,
  },

  props: {
    user: Object,
  },

  data() {
    return {
      visible: {
        delete: false,
        resend: false,
        role: false,
        update: false,
      },
    }
  },

  methods: {
    onClick() {
      if (this.user.verified) {
        this.visible.update = true
      } else {
        this.visible.resend = true
      }
    },
    onSuccess(key) {
      this.visible[key] = false
      this.$emit('success')
    },
    onMenuClick(e) {
      this.visible[e.key] = true
    },
  },
}
</script>
